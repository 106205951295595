import React, { useEffect, useState } from 'react'
import DynamicTable from '../../components/DynamicTable'
import CssBaseline from '@material-ui/core/CssBaseline'
import { API, input } from 'aws-amplify';
import DForm from '../../components/DForm';
import ConfirmationModal from '../../components/ConfirmationModal';
import useUIState from '../../hooks/useUIstate';
import { productFormToReqBody } from './ProductController';
import { s3put, s3remove } from '../../helpers/commonFunctions';
import { Product } from '../../interfaces/IProduct';
import TranslationModal from '../../components/TranslationModal';

function ProductsTable(props) {
    const { setShowLoadingModal, setToastMessage, setShowToastMessage, setToastType } = useUIState();

    const LockerSizesOptions = [
        { value: "180mm x 200mm", label: "180mm x 200mm" },
        { value: "180mm x 300mm", label: "180mm x 300mm" },
        { value: "180mm x 400mm", label: "180mm x 400mm" },
        { value: "247mm x 300mm", label: "247mm x 300mm" },
        { value: "372mm x 300mm", label: "372mm x 300mm" },
        { value: "NVT", label: "NVT" },
    ]


    const [data, setData]: [any[], any] = useState([]);
    const [showForm, setShowForm]: [boolean, any] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal]: [boolean, any] = useState(false);
    const [formValues, setFormValues]: [any, any] = useState({});
    const [activeRow, setActiveRow]: [any, any] = useState(null);
    const [formMode, setFormMode]: [string, any] = useState("");
    const [formObj, setFormObj]: [Record<string, any>[], any] = useState([]);
    const [ProductCategoriesOptions, setProductCategoriesOptions]: any = useState([]);
    const [UserConfigData, setUserConfigData]: [any, any] = useState([]);
    const [showTranslationModal, setShowTranslationModal]: [boolean, any] = useState(false);
    const [translationFormModel, setTranslationFormModel]: [any, any] = useState({});
    const [translationFormFieldsModel, setTranslationFormFieldsModel]: [any, any] = useState({});
    const [productForTranslate, setProductForTranslate]: [any, any] = useState({});


    useEffect(() => {
        const value = localStorage.getItem("UserConfigData");
        if (typeof value === 'string') {
            setUserConfigData(JSON.parse(value));
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (props.productCategories.length) {
            createProductCategoriesOptions();
        }
    }, [props.data])

    function createProductCategoriesOptions() {
        const _pCatOptions: any[] = [];
        props.productCategories.forEach(pc => {
            _pCatOptions.push({
                value: pc.Id,
                label: pc.Title,
            })
        });
        setProductCategoriesOptions(_pCatOptions);
        renderData(_pCatOptions);
    }

    async function renderData(productCategoriesOpts) {
        const productsRender: any[] = [];

        props.data.forEach(p => {

            let attr = p.Attributes;
            attr = attr.replace(/'/g, '"');
            attr = JSON.parse(attr);

            let pcTitle = productCategoriesOpts.find(pc => pc.value === p.ProductCategoryId).label;

            productsRender.push({
                Id: p.Id,
                ProductCategoryId: p.ProductCategoryId,
                ProductCategoryTitle: pcTitle,
                Code: p.Code,
                DefaultPrice: p.DefaultPrice,
                VAT: p.VAT,
                IsOnStartPage: p.IsOnStartPage ? 'Yes' : 'No',
                Title: p.Title,
                Description: p.Description,
                Avatar: p.Avatar ? p.Avatar : " ",
                ShelfLifeDays: attr.ShelfLifeDays,
                LockerSize: attr.LockerSize,
                Priority: attr.Priority,
                Weight: attr.Priority === "Weight" ?
                    attr.Weight : "",
                Amount: attr.Priority === "Amount" ?
                    attr.Amount : "",
                Enabled: attr.Enabled ? 'Yes' : 'No',
            })
        });

        setData(productsRender);
        setShowLoadingModal(false);
    }


    async function createOrUpdateProduct(reqBody: Product) {
        let path = '/Products';
        let body: Product = reqBody;
        if (reqBody.Id) { body.Id = reqBody.Id };
        let res;
        if (reqBody.Id) {
            res = await API.put('AE', path, { body });
        } else {
            res = await API.post('AE', path, { body });
        }
        return res;
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'Id',
                hide: true,
            },
            {
                Header: 'ProductCategoryId',
                accessor: 'ProductCategoryId',
                hide: true,
            },
            {
                Header: 'StoreId',
                accessor: 'StoreId',
                hide: true,
            },
            {
                Header: 'Code',
                accessor: 'Code',
            },
            {
                Header: 'Naam',
                accessor: 'Title',
            },
            {
                Header: 'Categorie',
                accessor: 'ProductCategoryTitle',
            },
            {
                Header: 'Standaard prijs(€)',
                accessor: 'DefaultPrice',
            },
            {
                Header: 'BTW(%)',
                accessor: 'VAT',
            },
            {
                Header: 'Beschrijving',
                accessor: 'Description',
                hide: true,
            },
            {
                Header: 'Avatar',
                accessor: 'Avatar',
                hide: true,
            },
            {
                Header: 'Houdbaarheidsdagen',
                accessor: 'ShelfLifeDays',
                // hide: true,
            },
            {
                Header: 'Hoeveelheid / gewicht',
                accessor: 'Priority',
                // hide: true,
            },
            {
                Header: 'Gewicht',
                accessor: 'Weight',
                // hide: true,
            },
            {
                Header: 'Hoeveelheid',
                accessor: 'Amount',
                // hide: true,
            },
            {
                Header: 'Locker grootte',
                accessor: 'LockerSize',
                // hide: true,
            },
            {
                Header: 'Populair product',
                accessor: 'IsOnStartPage',
            },
            {
                Header: 'Actief',
                accessor: 'Enabled',
            },
        ],
        []
    );

    function prepForm(row) {
        const rowValues = row !== null ? row.original : null;
        const addingRow = rowValues === null;

        const _formValues = {
            Code: addingRow ? "" : rowValues.Code,
            Title: addingRow ? "" : rowValues.Title,
            DefaultPrice: addingRow ? 0 : rowValues.DefaultPrice,
            VAT: addingRow ? 9 : rowValues.VAT,
            IsOnStartPage: addingRow ? false : rowValues.IsOnStartPage === "Yes" ? true : false,
            Enabled: addingRow ? true : rowValues.Enabled === "Yes" ? true : false,
            Description: addingRow ? "" : rowValues.Description,
            Avatar: addingRow ? "" : rowValues.Avatar,
            ShelfLifeDays: addingRow ? "" : rowValues.ShelfLifeDays,
            LockerSize: addingRow ? LockerSizesOptions[0].value : rowValues.LockerSize,
            Priority: addingRow ? "Weight" : rowValues.Priority,
            ProductCategoryId: addingRow ? ProductCategoriesOptions[0].value : rowValues.ProductCategoryId,
            Amount: addingRow ? "" : rowValues.Amount,
            Weight: addingRow ? "" : rowValues.Weight,
        }

        const formObj = [
            {
                name: "Code",
                type: "text",
                label: "Code",
                required: true,
                disabled: addingRow ? false : true,
                initialValue: addingRow ? "" : rowValues.Code
            },
            {
                name: "Title",
                type: "text",
                label: "Naam",
                required: true,
                initialValue: addingRow ? "" : rowValues.Title
            },
            {
                name: "ProductCategoryId",
                type: "select",
                label: "Categorie",
                required: true,
                initialValue: _formValues.ProductCategoryId,
                options: ProductCategoriesOptions
            },
            {
                name: "DefaultPrice",
                type: "number",
                label: "Standaard prijs(€)",
                required: true,
                initialValue: addingRow ? "" : rowValues.DefaultPrice
            },
            {
                name: "VAT",
                type: "number",
                label: "BTW (%)",
                initialValue: addingRow ? "" : rowValues.VAT
            },
            {
                name: "Description",
                type: "text",
                label: "Beschrijving",
                initialValue: addingRow ? "" : rowValues.Description
            },
            {
                name: "ShelfLifeDays",
                type: "number",
                label: "Houdbaarheidsdagen",
                required: true,
                initialValue: addingRow ? "" : rowValues.ShelfLifeDays
            },
            {
                name: "LockerSize",
                type: "select",
                label: "Locker grootte",
                required: true,
                initialValue: addingRow ?
                    LockerSizesOptions[0].value :
                    rowValues.LockerSize,
                options: LockerSizesOptions
            },
            {
                name: "Priority",
                type: "select",
                label: "Hoeveelheid / gewicht",
                required: true,
                initialValue: addingRow ? "Weight" : rowValues.Priority,
                options: [
                    { label: "Gewicht", value: "Weight" },
                    { label: "Aantal", value: "Amount" },
                ]
            },
            {
                name: "Weight",
                type: "text",
                label: "Gewicht",
                initialValue: addingRow ? "" : rowValues.Weight
            },
            {
                name: "Amount",
                type: "number",
                label: "Hoeveelheid",
                initialValue: addingRow ? "" : rowValues.Amount
            },
            {
                name: "IsOnStartPage",
                type: "checkbox",
                label: "Populair product",
                checked: addingRow ? false : rowValues.IsOnStartPage === 'Yes' ? true : false
            },
            {
                name: "Enabled",
                type: "checkbox",
                label: "Actief",
                checked: addingRow ? false : rowValues.Enabled === 'Yes' ? true : false
            },

            {
                name: "Avatar",
                type: "file",
                label: "Selecteer",
                objUrl: addingRow ? "" : rowValues.Avatar.trim() ? UserConfigData.Customer + '/images/products/pid' + rowValues.Id + "_" + rowValues.Avatar : "",
                maxHeight: 320,
                maxWidth: 320
            },

        ];
        setFormObj(formObj);

        setActiveRow(rowValues);
        setFormMode(rowValues === null ? "Add" : "Edit");
        setFormValues(_formValues);
        setShowForm(true);
    }

    function prepTranslation(row) {
        setShowLoadingModal(true);
        API.get("AE", `/Translations/GetProductTranslations?productId=${row.values.Id}`, { response: true })
            .then(res => {
                let data = res.data;
                let fields = [{ name: 'LanguageCode', input: false }, { name: 'Title', input: true }, { name: 'Description', input: true }];
                setTranslationFormModel(data);
                setTranslationFormFieldsModel(fields);
                setProductForTranslate(row.values);
                setShowTranslationModal(true);
            })
            .catch(error => {
                console.log(error);
                setToastType("error");
                setToastMessage("Error getting products");
                setShowToastMessage(true);
            })
            .finally(() => {
                setShowLoadingModal(false);
            })
    }

    async function submit(values) {

        let validated = true;

        if (values.VAT === 0) {
            setToastType("error");
            setToastMessage("Het is niet mogelijk om producten met 0% BTW op te slaan");
            setShowToastMessage(true);
            validated = false;
        }

        if (!values.Avatar || values.Avatar === " ") {
            setToastType("error");
            setToastMessage("You have to add an image for the product");
            setShowToastMessage(true);
            validated = false;
        }

        // check for unique code
        if (data.find(el => el.Code === values.Code)) {
            let valueExists;
            if (activeRow === null) {
                valueExists = true;
            } else {
                if (data.find(el => el.Code === values.Code).Id !== activeRow.Id) {
                    valueExists = true;
                }
            }
            if (valueExists) {
                setToastType("error");
                setToastMessage(`Code already exists`);
                setShowToastMessage(true);
                validated = false;
            }
        }

        if (validated) {
            setShowLoadingModal(true);
            const reqBody = productFormToReqBody(activeRow, values);

            setShowForm(false);

            const res = await createOrUpdateProduct(reqBody);
            if (res) {
                setToastType("success");
                setToastMessage(`Product ${activeRow === null ? "added" : "edited"} successfully!`);
                setShowToastMessage(true);
                props.getData();
            } else {
                setToastType("error");
                setToastMessage(`Error ${activeRow === null ? "adding" : "editing"} Product`);
                setShowToastMessage(true);
            }

            let putResp;
            let imageChange;

            if (activeRow) {
                if (values.Avatar !== activeRow.Avatar) {
                    imageChange = true;
                    putResp = await s3put(`${UserConfigData.Customer}/images/products/pid${res.Id}_${reqBody.Avatar}`, values.Avatar);
                }
            } else {
                imageChange = true;
                putResp = await s3put(`${UserConfigData.Customer}/images/products/pid${res.Id}_${reqBody.Avatar}`, values.Avatar);
            }

            if (!putResp && imageChange) {
                setToastType("error");
                setToastMessage("Image upload failed");
                setShowToastMessage(true);
            }

            setShowLoadingModal(false);
        }
    }

    async function submitTranslationValue(rows) {
        let path = `/Translations/AddOrUpdateProductTranslations?productId=${productForTranslate.Id}`;
        let body = rows;
        await API.post('AE', path, { body });
        setShowTranslationModal(false);
        setToastType("success");
        setToastMessage("Translations saved successfully!");
        setShowToastMessage(true);
    }

    function handleDeleteConfirmation(row) {
        setActiveRow(row);
        setShowConfirmationModal(true);
    }

    async function deleteEntry() {
        let path = '/Products';
        let myInit = {}
        try {
            setToastType("success");
            setToastMessage("Product deleted successfully!");
            setShowToastMessage(true);
            return await API.del('AE', path + "?productId=" + activeRow.original.Id, myInit);
        } catch (err) {
            console.log(err);
            setToastType("error");
            setToastMessage("Error deleting Product");
            setShowToastMessage(true);
            return null;
        }
    }

    async function confirmDelete() {
        setShowLoadingModal(true);
        setShowConfirmationModal(false);
        const deleted = await deleteEntry();
        if (deleted) {
            if (activeRow.original.Avatar.trim()) {
                const filePath = `${UserConfigData.Customer}/images/products/pid${activeRow.original.Id}_${activeRow.original.Avatar}`
                const res = await s3remove(filePath);
                if (!res) {
                    setToastType("error");
                    setToastMessage("Image delete failed");
                    setShowToastMessage(true);
                }
            }
        }
        props.getData();
    }


    return (
        <div>
            <h2 className="mb-05">Producten</h2>
            <CssBaseline />
            <DynamicTable
                className="Products"
                addRow={() => prepForm(null)}
                editRow={(row) => prepForm(row)}
                editRowTranslation={(row) => prepTranslation(row)}
                // deleteRow={(row) => handleDeleteConfirmation(row)}
                columns={columns}
                data={data}
                loading={false}
                globalFilter={true}
                showActiveField={true}
            />
            {
                showForm ?
                    <DForm
                        formObj={formObj}
                        formMode={formMode}
                        initialValues={formValues}
                        closeModal={() => setShowForm(false)}
                        submit={submit}
                    />
                    : null
            }
            {
                showTranslationModal ?
                    <TranslationModal
                        rows={translationFormModel}
                        fields={translationFormFieldsModel}
                        product={productForTranslate}
                        closeModal={() => setShowTranslationModal(false)}
                        save={submitTranslationValue}
                    />
                    : null
            }
            {showConfirmationModal ?
                <ConfirmationModal
                    message={"Product verwijderen?"}
                    confirm={confirmDelete}
                    closeModal={() => setShowConfirmationModal(false)}
                />
                : null}
        </div>
    )
}

export default ProductsTable;
