import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paperInner: {
    width: "60vw",
    "& table": {
      borderCollapse: "collapse",
      width: "100%",
    },
    "& .header": {
      backgroundColor: "#689f38",
      color: "rgb(255, 255, 255)",
      height: "35px",
    },
    "& th": {
      textAlign: "left",
    },
    "& tr": {
      borderSpacing: "5em",
      borderBottom: "1px solid #ddd",
      display: "table",
      width: "100%",
      tableLayout: "fixed",
      height: '30px'
    },
    "& tbody": {
      display: "block",
      maxHeight: "45vh",
      overflow: "auto",
    },
    "& thead": {
      width: "calc( 100% - 1em )",
    },
  },
}));

export default function LogViewTable(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.paperInner}>
        <table>
          <thead>
            <tr className="header">
              <th>Timestamp</th>
              <th>Message</th>
              <th>Type</th>
              <th>Message Type</th>
            </tr>
          </thead>
          <tbody>
            {props.rows ? (
              props.rows.map((m) => {
                return (
                  <tr>
                    <td>{moment(m.Timestamp).format('DD/MM/yyyy hh:mm:ss')}</td>
                    <td>{m.Message}</td>
                    <td>{m.Type}</td>
                    <td>{m.MessageType}</td>
                  </tr>
                );
              })
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
