import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import { Feature } from "flagged";
import {
  MdRemove,
  MdAdd,
  MdBugReport,
  MdSwapVert,
  MdFastForward,
  MdUpdate,
} from "react-icons/md";
import { useEffect, useState } from "react";
import { getInt } from "../helpers/commonFunctions";
import useUIState from "../hooks/useUIstate";
import { API } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& .MuiInputBase-root, & .multi-select, & .form-group-fileinput input": {
      margin: theme.spacing(1),
      width: 320,
    },
    "& .MuiSelect-select": {
      padding: "10.5px 14px",
    },
    "& label": {
      marginLeft: theme.spacing(1),
      marginBottom: "0",
      fontSize: "0.8rem",
    },
    "& .MuiButtonBase-root ": {
      margin: theme.spacing(1),
      width: 320,
      padding: "9px 14px",
      marginTop: "12px",
    },
    "& .MuiCheckbox-root": {
      width: "auto",
    },
    "& .form-content": {
      maxWidth: "680px",
      flexWrap: "wrap",
    },
    "& .form-group-checkbox": {
      width: "320px",
      margin: "0 8px",
    },
    "& .form-group-qrcode": {
      marginLeft: "30px",
    },
    "& .center-items": {
      alignItems: "center",
    },
    "& .form-group-fileinput input": {
      padding: "8.5px 14px",
      border: "1px solid #ccc",
      borderRadius: "4px",
    },
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "5px",
    borderRadius: "4px",
    outline: "none",
  },
  paperInner: {
    display: "flex",
    alignItems: "center",

    "&>*": {
      margin: "7px",
    },
  },
  header: {
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: '16pt'
  },
  controlTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  titleIcon: {
    marginRight: '1em'
  },
  control: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

export default function JofemarChannelConfigModal(props) {
  const classes = useStyles();
  const [value, setValue]: [any, any] = useState({});
  const [runTime, setRunTime]: [number, any] = useState(0);
  const [dispenseSpeed, setDispenseSpeed]: [number, any] = useState(0);
  const [travelHeight, setTravelHeight]: [number, any] = useState(0);
  const [loaded, setLoaded]: [boolean, any] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setValue(props.value);
      setRunTime(props.value.col.UpdateKeepRunningForMs);
      setTravelHeight(props.value.col.ElevatorAdjustMs);
      setDispenseSpeed(props.value.col.DispensingSpeed);
      setLoaded(true);
    }
  });
  const {
    setShowLoadingModal,
    setToastMessage,
    setShowToastMessage,
    setToastType,
  } = useUIState();

  function handleSave() {
    value.col.UpdateKeepRunningForMs = runTime;
    value.col.ElevatorAdjustMs = travelHeight;
    value.col.DispensingSpeed = dispenseSpeed;
    saveJofemarConfiguration();
    props.closeModal();
  }

  function saveJofemarConfiguration() {
    const valuesToSave = [
      {
        Code: props.value.code,
        KeepRunningForMs: runTime,
        ElevatorAdjustMs: travelHeight,
        DispensingSpeed: dispenseSpeed,
      },
    ];    
    const reqBody = {
      BlockId: props.value.jofemar["Id"],
      JofemarChannelConfigs: valuesToSave,
    };
    console.log(reqBody);
    let body = reqBody;
    console.log(body);
    setShowLoadingModal(true);
    API.put("AE", `/Stores/UpdateJofemarConfig`, { body })
      .then((res) => {
        console.log(res);
        setShowLoadingModal(true);
      })
      .catch((error) => {
        console.log(error);
        setToastType("error");
        setToastMessage("Error updating JofemarConfig");
        setShowToastMessage(true);
      })
      .finally(() => {
        setShowLoadingModal(false);
      });
  }

  function handleTest() {
    const valuesToDispense = 
      {
        Name: value.name,
        Code: props.value.code,
        UpdateKeepRunningForMs: runTime,
        ElevatorAdjustMs: travelHeight,
        DispensingSpeed: dispenseSpeed,
      };
    props.testValues(valuesToDispense);
  }

  function handleRunTimeChange(
    increment: boolean,
    value: string | undefined = undefined
  ) {
    const maxPusherTime = 50;
    const minValue = 0;
    if (value) {
      var newValue = getInt(value, 0, 0);
      setRunTime(newValue <= maxPusherTime ? newValue : maxPusherTime);
    } else if (value === '') {
      setRunTime(0);
    } else {
      var newValue = increment ? runTime + 1 : runTime - 1;
      if (newValue <= maxPusherTime && newValue >= minValue) {
        setRunTime(newValue);
      }
    }
  }

  function handleDispensingSpeedChange(
    increment: boolean,
    value: string | undefined = undefined
  ) {
    const maxPusherSpeed = 19;
    const minValue = 0;
    if (value) {
      var newValue = getInt(value, 0, 0);
      setDispenseSpeed(newValue <= maxPusherSpeed ? newValue : maxPusherSpeed);
    } else if (value === '') {
      setDispenseSpeed(0);
    } else {
      var newValue = increment ? dispenseSpeed + 1 : dispenseSpeed - 1;
      if (newValue <= maxPusherSpeed && newValue >= minValue) {
        setDispenseSpeed(newValue);
      }
    }
  }

  function handleChangeElevator(
    increment: boolean,
    value: string | undefined = undefined
  ) {
    const maxAdjust = 500;
    const minAdjust = -500;
    const incrementBy = 25;
    if (value) {
      var newValue = getInt(value, -500, 0);
      setTravelHeight(newValue <= maxAdjust ? newValue : maxAdjust);
    } else if (value === '') {
      setTravelHeight(0);
    } else {
      var newValue = increment
        ? travelHeight + incrementBy
        : travelHeight - incrementBy;
      if (increment) {
        setTravelHeight(newValue <= maxAdjust ? newValue : maxAdjust);
      } else {
        setTravelHeight(newValue >= minAdjust ? newValue : minAdjust);
      }
    }
  }

  return (
    <Modal
      aria-labelledby="DForm"
      aria-describedby="DForm"
      open={true}
      className={classes.root}
    >
      <div tabIndex={-1} className={classes.paper}>
        <div className={classes.header}>
          <h3>Rij: {value?.rowNumber} Kanaal: {value?.channelNumber}</h3>
        </div>
        <div style={{ flexDirection: "column", textAlign: "center" }}>
          <div className={classes.controlTitle}>
            <MdUpdate size={25} className={classes.titleIcon} />
            <h3>Run Time</h3>
          </div>
          <div className={classes.control}>
            <Button
              variant="contained"
              className="gray-btn decrement-runtime"
              onClick={() => handleRunTimeChange(false)}
              style={{ width: "40px" }}
            >
              <MdRemove size={22} />
            </Button>
            <TextField
              className="UpdatedRuntimeValue"
              type="text"
              onChange={(e) => handleRunTimeChange(true, e.target.value)}
              value={runTime}
              variant="outlined"
              size="small"
            />
            <Button
              variant="contained"
              className="gray-btn increment-runtime"
              onClick={() => handleRunTimeChange(true)}
              style={{ width: "40px" }}
            >
              <MdAdd size={22} />
            </Button>
          </div>

          <div className={classes.controlTitle}>
            <MdFastForward size={25} className={classes.titleIcon} />
            <h3>Dispensing Speed</h3>
          </div>
          <div>
            <span style={{fontWeight: dispenseSpeed >= 0 && dispenseSpeed <= 9 ? 'bold': 'inherit'}}>Continuous: 0 - 9</span>
            <br />
            <span style={{fontWeight: dispenseSpeed >= 10 && dispenseSpeed <= 19 ? 'bold': 'inherit'}}>Pulsing: 10 - 19</span>
          </div>
          <div className={classes.control}>
            <Button
              variant="contained"
              className="gray-btn decrement-runtime"
              onClick={() => handleDispensingSpeedChange(false)}
              style={{ width: "40px" }}
            >
              <MdRemove size={22} />
            </Button>
            <TextField
              className="UpdatedRuntimeValue"
              type="text"
              onChange={(e) =>
                handleDispensingSpeedChange(true, e.target.value)
              }
              value={dispenseSpeed}
              variant="outlined"
              size="small"
            />
            <Button
              variant="contained"
              className="gray-btn increment-runtime"
              onClick={() => handleDispensingSpeedChange(true)}
              style={{ width: "40px" }}
            >
              <MdAdd size={22} />
            </Button>
          </div>

          <Feature name="adjustjofemarelevatorheight">
            <div className={classes.controlTitle}>
              <MdSwapVert size={25} className={classes.titleIcon}/>
              <h3>Elevator Hight</h3>
            </div>
            <div className={classes.control}>
              <Button
                variant="contained"
                className="gray-btn decrement-runtime"
                onClick={() => handleChangeElevator(false)}
                style={{ width: "40px" }}
              >
                <MdRemove size={22} />
              </Button>
              <TextField
                className="elevatorAdjustValue"
                type="text"
                onChange={(e) => handleChangeElevator(true, e.target.value)}
                value={travelHeight}
                variant="outlined"
                size="small"
              />
              <Button
                variant="contained"
                className="gray-btn increment-runtime"
                onClick={() => handleChangeElevator(true)}
                style={{ width: "40px" }}
              >
                <MdAdd size={22} />
              </Button>
            </div>
          </Feature>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            onClick={handleTest}
            style={{ backgroundColor: "lightgreen" }}
          >
            Test Configuration <MdBugReport size={22} />
          </Button>
        </div>
        <div className="flex">
          <Button variant="contained" onClick={props.closeModal}>
            Annuleren
          </Button>

          <Button
            id="formSubmitBtn"
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            Opslaan
          </Button>
        </div>
      </div>
    </Modal>
  );
}
