import React, { useEffect, useState } from 'react';

interface Option {
    key: string;
    value: string;
}

interface TableRow {
    LanguageCode: string;
    Title: string;
    Description: string;
    ProductId: number;
}

interface TranslationRowField {
    name: string;
    input: boolean;
}

const initialOptions: Option[] = [
    { key: 'EN', value: 'EN' },
    { key: 'FR', value: 'FR' },
];

function ProductTranslationTable(props) {
    useEffect(() => {
        setTranslationRowFields(props.fields);
        setRows(props.rows);
        let codes = rows.map(m => m.LanguageCode);
        setAvailableOptions(availableOptions.filter(opt => !codes.includes(opt.value)));
    }, [props])
    const [selectedOption, setSelectedOption] = useState<string>('');
    const [rows, setRows] = useState<TableRow[]>([]);
    const [translationRowFields, setTranslationRowFields] = useState<TranslationRowField[]>([]);
    const [availableOptions, setAvailableOptions] = useState<Option[]>(initialOptions);

    const handleAddRow = () => {
        if (selectedOption) {
            const selectedOptionObj = availableOptions.find(opt => opt.value === selectedOption);
            if (selectedOptionObj) {
                let updatedRows = [...rows, { LanguageCode: selectedOption, Title: '', Description: '', ProductId: props.productId }];
                props.handleRowSet(updatedRows);
                setRows(updatedRows);
                setAvailableOptions(availableOptions.filter(opt => opt.value !== selectedOption));
                setSelectedOption('');
            }
        }
    };

    const handleDeleteRow = (index: number) => {
        const deletedRow = rows[index];
        let updatedRows = rows.filter((_, i) => i !== index);
        props.handleRowSet(updatedRows);
        setRows(updatedRows);
        setAvailableOptions([...availableOptions, { key: deletedRow.LanguageCode, value: deletedRow.LanguageCode }]);
    };

    const handleUpdateField = (newRows: TableRow[]) => {
        props.handleRowSet(newRows);
        setRows(newRows);
    };

    return (
        <div className="bg-gray-900 text-gray-200 p-4">
            <div className="flex flex-col space-y-4">
                <div className="flex items-center space-x-2">
                    <h3>Product Translations - {props.productName}</h3>
                </div>
                <div className="flex items-center space-x-2" style={{marginBottom: '1em'}}>
                    <select
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                        className="bg-gray-800 border border-gray-700 rounded px-3 py-2 flex-grow"
                        style={{marginRight: '0.25em'}}
                    >
                        <option value="">Selecteer een optie</option>
                        {availableOptions.map(option => (
                            <option key={option.key} value={option.value}>
                                {option.value}
                            </option>
                        ))}
                    </select>
                    <button
                        onClick={handleAddRow}
                        disabled={!selectedOption}
                        className="w-10 h-10 flex items-center justify-center rounded-md bg-purple-600 text-white hover:bg-purple-700 disabled:bg-gray-700 disabled:text-gray-500 disabled:cursor-not-allowed"
                        aria-label="Add Row"
                    >
                        &#10133;
                    </button>
                </div>

                <table className="w-full border-collapse">
                    <thead>
                        <tr className="bg-gray-800">
                            <th className="border-b border-gray-700 p-3" align='left'>Language</th>
                            <th className="border-b border-gray-700 p-3" align='left'>Title</th>
                            <th className="border-b border-gray-700 p-3" align='left'>Description</th>
                            <th className="border-b border-gray-700 p-3" align='left'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => (
                            <tr key={row.LanguageCode} className="border-b border-gray-800">
                                {
                                    translationRowFields.map(field => {
                                        if (field.input) {
                                            return <td className="p-3">
                                                <input
                                                    type="text"
                                                    value={row[field.name]}
                                                    onChange={(e) => {
                                                        const newRows = [...rows];
                                                        newRows[index][field.name] = e.target.value;
                                                        handleUpdateField(newRows);
                                                    }}
                                                    className="w-full bg-gray-800 border border-gray-700 rounded px-2 py-1 text-gray-200"
                                                    style={{ width: '100%' }}
                                                />
                                            </td>
                                        } else {
                                            return <td>
                                                {row[field.name]}
                                            </td>
                                        }

                                    })
                                }
                                <td className="p-3">
                                    <button
                                        onClick={() => handleDeleteRow(index)}
                                        className="w-8 h-8 flex items-center justify-center rounded-md bg-red-600 text-white hover:bg-red-700"
                                        aria-label="Delete Row"
                                    >
                                        &#10134;
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ProductTranslationTable;