import { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from "@material-ui/core";
import ProductTranslationTable from "./ProductTranslationTable";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        '& .MuiInputBase-root, & .multi-select, & .form-group-fileinput input': {
            margin: theme.spacing(1),
            width: 320,
        },
        '& .MuiSelect-select': {
            padding: "10.5px 14px",
        },
        '& label': {
            marginLeft: theme.spacing(1),
            marginBottom: '0',
            fontSize: '0.8rem'
        },
        '& .MuiButtonBase-root ': {
            margin: theme.spacing(1),
            width: 320,
            padding: "9px 14px",
            marginTop: "12px",
        },
        '& .MuiCheckbox-root': {
            width: 'auto'
        },
        '& .form-content': {
            maxWidth: '680px',
            flexWrap: "wrap"
        },
        '& .form-group-checkbox': {
            width: "320px",
            margin: "0 8px"
        },
        '& .form-group-qrcode': {
            marginLeft: "30px"
        },
        '& .center-items': {
            alignItems: "center"
        },
        '& .form-group-fileinput input': {
            padding: "8.5px 14px",
            border: "1px solid #ccc",
            borderRadius: "4px"
        },
    },
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: "5px",
        borderRadius: "4px",
        outline: "none"
    },
    paperInner: {
        display: "flex",
        alignItems: "center",

        '&>*': {
            margin: '7px'
        }
    }
}));


export default function TranslationModal(props) {
    useEffect(() => {
        setRows(props.rows);
    }, [props])
    const classes = useStyles();    
    const [rows, setRows] = useState<any[]>([]);

    function handleRowSet(rows) {
        setRows(rows);
    }

    function handleSave() {
        //todo add productId and save
        props.save(rows);
    }

    return (
        <Modal
            aria-labelledby="DForm"
            aria-describedby="DForm"
            open={true}
            className={classes.root}
        >
            <div tabIndex={-1} className={classes.paper}>
                <ProductTranslationTable fields={props.fields} rows={rows} handleRowSet={handleRowSet} productId={props.product.Id} productName={props.product.Title}></ProductTranslationTable>
                <div className="flex">

                    <Button
                        variant="contained"
                        onClick={
                            props.closeModal
                        }
                    >
                        Annuleren
                    </Button>

                    <Button
                        id="formSubmitBtn"
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                    >
                        Opslaan
                    </Button>
                </div>
            </div>


        </Modal>
    );


}


