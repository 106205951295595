import { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { API } from "aws-amplify";
import useUIState from "../hooks/useUIstate";
import { Box, Button, Tab, Tabs } from "@material-ui/core";
import LogViewTable from "./LogViewTable";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "5px",
    borderRadius: "4px",
    outline: "none",
    height: "60vh",
  },
  paperInner: {
    width: "60vw",
    "& .buttons": {
      margin: "1em",
      position: "absolute",
      bottom: 0,
    },
    "& .button": {
      marginLeft: "1em",
      width: "200px",
    },
  },
}));

export interface LogParameters {
  EntityId?: string;
  Reference?: string;
  CloseModal: any;
  TabFilterField?: string;
  TabFilterValues?: string[];
}

export interface LogModel {
  Timestamp: string;
  Type: string;
  Message: string;
  Exception: string;
  Stacktrace: string;
  MessageType: string;
}

export default function LogViewModal(props: LogParameters) {
  const classes = useStyles();
  const [rows, setRows]: [LogModel[], any] = useState([]);
  const [currentRows, setCurrentRows]: [LogModel[], any] = useState([]);
  const [tabValue, setTabValue]: [number, any] = useState(0);
  const [tabFilterValue, setTabFilterValue]: [string, any] = useState("");
  const {
    setShowLoadingModal,
    setToastMessage,
    setShowToastMessage,
    setToastType,
  } = useUIState();

  useEffect(() => {
    setShowLoadingModal(true);
    API.get("AE", `/Log/GetByReference?reference=test`, { response: true })
      .then((response) => {
        setRows(response.data);
        if (props.TabFilterField) {
          setTabFilterValue(
            props.TabFilterValues ? props.TabFilterValues[0] : ""
          );
        }
      })
      .catch((error) => {
        console.log(error);
        setToastType("error");
        setToastMessage("Error getting products");
      })
      .finally(() => {
        setShowLoadingModal(false);
      });
  }, []);

  useEffect(() => {
    let filterField = props.TabFilterField ? props.TabFilterField : "";
    setCurrentRows(rows.filter((f) => f[filterField] === tabFilterValue));
  }, [tabFilterValue]);

  function refresh() {}

  function handleTabChange() {}

  return (
    <Modal
      aria-labelledby="Log-modal"
      aria-describedby="Log-modal"
      open={true}
      className={classes.root}
    >
      <div tabIndex={-1} className={classes.paper}>
        <div className={classes.paperInner}>
          <div>
            {props.TabFilterField ? (
              <>
                <Box style={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                    style={{ marginBottom: "0.5em" }}
                  >
                    {props.TabFilterValues ? (
                      props.TabFilterValues.map((m) => {
                        return (
                          <Tab
                            key={m}
                            label={m.replace(/([a-z])([A-Z])/g, '$1 $2')}
                            value={props.TabFilterValues?.indexOf(m)}
                            onClick={() => {
                              setTabValue(props.TabFilterValues?.indexOf(m));
                              setTabFilterValue(m);
                            }}
                          />
                        );
                      })
                    ) : (
                      <>No filter values found</>
                    )}
                  </Tabs>
                  <LogViewTable rows={currentRows}></LogViewTable>
                </Box>
              </>
            ) : (
              <>
                <LogViewTable rows={rows}></LogViewTable>
              </>
            )}
          </div>
          <div className="buttons">
            <Button
              variant="contained"
              onClick={props.CloseModal}
              className="button"
            >
              Annuleren
            </Button>
            <Button
              id="confirmBtn"
              variant="contained"
              onClick={refresh}
              color="primary"
              className="button"
            >
              Vernieuwen
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
